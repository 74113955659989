import { path } from "d3";


export const TeachersidebarItems = [
    {
        title: "Dashboard",
        path: "teacher/dashboard ",
        icon: <i className="fas fa-tachometer-alt"></i>, // Replace with your icon component
        subtitle: null,
        subpath: null,
    },

    {
        title: "Timetable",
        path: "teacher/dashboard/timetable",
        icon: <i className="fas fa-cog"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: "Class Attendance",
        path: "teacher/dashboard/class/attendance-list",
        icon: <i className="fas fa-question-circle"></i>,
        Links: [
            {
                title: "Attendance List",
                path: "/teacher/dashboard/class/attendance-list",
                icon: <i className="fas fa-plus"></i>,
                subtitle: "Add a new attendance record",
            },

        ]
    },
    {
        title: "Latest Notice",
        path: "teacher/dashboard/latest-notice",
        icon: <i className="fas fa-bell"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: "Subject",
        path: "teacher/dashboard/class-subject-list",
        icon: <i className="fas fa-bell"></i>,

    }, {
        title: "Add Chapter  Material",
        path: "teacher/dashboard/material/Class-material",
        icon: <i className="fas fa-bell"></i>,
    },
    {
        title: "Holiday List",
        path: "teacher/dashboard/holiday",
        icon: <i className="fas fa-bell"></i>,

    },
    {
        title: "Result",
        path: "teacher/dashboard/result",
        icon: <i className="fas fa-bell"></i>,
        Links: [
            {
                title: "Create Result",
                path: "/teacher/dashboard/result",
                icon: <i className="fas fa-credit-card"></i>,
            },
            {
                title: "Search Created Result",
                path: "/teacher/dashboard/search/created-result",
                icon: <i className="fas fa-credit-card"></i>,
            },
        ]

    },
    {
        title: "Student Leave List",
        path: "teacher/dashboard/student-leave-list",
        icon: <i className="fas fa-bell"></i>,

    },
    {
        title: "My Leave",
        path: "teacher/dashboard/myleave",
        icon: <i></i>,
        Links: [{
            title: "Applly for Leave",
            path: "/teacher/dashboard/myleave",
            icon: <i className="fas fa-credit-card"></i>,
        },
        {
            title: "My Leave Applications",
            path: "/teacher/dashboard/my-leave-applications",
            icon: <i className="fas fa-plus"></i>,
        },
        ]
    },
    {
        title: " Assignment ",
        path: "teacher/dashboard/create/assignment",
        icon: <i className="fas fa-chart-pie"></i>,
        Links: [{
            title: "Create Assigment",
            path: "/teacher/dashboard/create/assignment",
            icon: <i className="fas fa-credit-card"></i>,
        },
        {
            title: "Get Due Assignment",
            path: "/teacher/dashboard/get-due-assignment",
            icon: <i className="fas fa-plus"></i>,
        },
        {
            title: "Subimmitted Assignment",
            path: "/teacher/dashboard/submitted-assignment",
            icon: <i className="fas fa-plus"></i>,
        },
        ]
    },
    {
        title: "Reset Password",
        path: "teacher/dashboard/reset-password",
        icon: <i className="fas fa-bell"></i>,
    },
    {
        title: "Help",
        path: "teacher/dashboard/help",
        icon: <i className="fas fa-credit-card"></i>,
    },
];

export const StudentSidebarItems = [
    {
        title: "Dashboard",
        path: "student/dashboard ",
        icon: <i className="fas fa-tachometer-alt"></i>, // Replace with your icon component
        subtitle: null,
        subpath: null,
    },
    {
        title: "Attendance ",
        path: "student/dashboard/attendance",
        icon: <i className="fas fa-credit-card"></i>,
        // subtitle: "Add Attendance",
        // subpath: "admin/dashboard/attendance/add",
        Links: [
            {
                title: "Attendance Summary",
                path: "/student/dashboard/attendance",
                icon: <i className="fas fa-plus"></i>,
            },

        ]
    },
    {
        title: "Timetable",
        path: "student/dashboard/timetable",
        icon: <i className="fas fa-cog"></i>,

    },
    {
        title: "Exam Timetable",
        path: "student/dashboard/exam-timetable",
        icon: <i className="fas fa-cog"></i>,

    },
    {
        title: "Notice Board",
        path: "student/dashboard/notice-board",
        icon: <i className="fas fa-credit-card"></i>,
    },
    {
        title: "Subject ",
        path: "student/dashboard/Subject",
        icon: <i className="fas fa-question-circle"></i>,
        Links: [
            {
                title: "Subject ",
                path: "/student/dashboard/Subject",
                icon: <i className="fas fa-plus"></i>,
            },
            {
                title: "All Subjects ",
                path: "/student/dashboard/Subject-list",
                icon: <i className="fas fa-plus"></i>,
            },
        ]
    },
    // {
    //     title: "Study Material",
    //     path: "student/dashboard/study-material",
    //     icon: <i className="fas fa-users"></i>,
    // },
    {
        title: "Parents ",
        path: "student/dashboard/parents",
        icon: <i className="fas fa-comments"></i>,

    },
    {
        title: "Result",
        path: "student/dashboard/Result",
        icon: <i className="fas fa-question-circle"></i>,

    },

    {
        title: "Report",
        path: "student/dashboard/my-reports",
        icon: <i className="fas fa-users"></i>,
    },
    {
        title: "My Leave",
        path: "student/dashboard/my-leave",
        icon: <i className="fas fa-bell"></i>,

    },
    {
        title: "Student Assignment ",
        path: "student/dashboard/assignment",
        icon: <i className="fas fa-chart-pie"></i>,
        Links: [{
            title: "Assignment ",
            path: "/student/dashboard/assignment",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "View Attendance",
        },
        {
            title: "Submitted Assignment",
            path: "/student/dashboard/submitted/Assignment",
            icon: <i className="fas fa-plus"></i>,
            subtitle: "Add a new attendance record",
        },],
    },
    // {
    //     title: "My Fee",
    //     path: "student/dashboard/my-fee",
    //     icon: <i className="fas fa-bell"></i>,
    // },
    {
        title: "Notification ",
        path: "student/dashboard/push-notification",
        icon: <i className="fas fa-chart-pie"></i>,

    },
    {
        title: "Holiday List",
        path: "student/dashboard/holiday",
        icon: <i className="fas fa-bell"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: "Academic ",
        path: "student/dashboard/academic-calender",
        Links: [
            {
                title: "Academic Calender",
                path: "/student/dashboard/academic-calender"
            },
            {
                title: "Academic Holiday ",
                path: "/student/dashboard/academic-holiday",
                icon: <i className="fas fa-credit-card"></i>,
            },

           
        ],
    },
    {
        title: "Reset Password",
        path: "student/dashboard/reset-password",
        icon: <i className="fas fa-bell"></i>,
    },
    {
        title: "Help",
        path: "student/dashboard/help",
        icon: <i className="fas fa-credit-card"></i>,
    },
];


export const ParentSidebarItems = [
    {
        title: "Dashboard",
        path: "parent/dashboard ",
        icon: <i className="fas fa-tachometer-alt"></i>, // Replace with your icon component
        subtitle: null,
        subpath: null,
    },
    {
        title: "Attendance ",
        path: "parent/dashboard/attendance",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [{
            title: "Student Attendance Overview",
            path: "/parent/dashboard/attendance",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Transport List",
        },
            // {
            //     title: "Student Attendance List",
            //     path: "/parent/dashboard/student-attendance-list",
            //     icon: <i className="fas fa-plus"></i>,
            //     subtitle: "all record",
            // },
        ]

    },
    {
        title: "Transport ",
        path: "parent/dashboard/transport",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [{
            title: "Transport Details",
            path: "/parent/dashboard/transport",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Transport List",
        }]

    },
    {
        title: "Timetable",
        path: "parent/dashboard/timetable",
        icon: <i className="fas fa-cog"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: "Announcement ",
        path: "parent/dashboard/announcment",
        icon: <i className="fas fa-comments"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: " Reports",
        path: "parent/dashboard/my-child-reports",
        icon: <i className="fas fa-users"></i>,
    },
    {
        title: " Leave ",
        path: "parent/dashboard/my-child-leaveList",
        icon: <i className="fas fa-users"></i>,
    },
    {
        title: "Fee",
        path: "parent/dashboard/my-fee",
        icon: <i className="fas fa-bell"></i>,
        Links: [{
            title: "Children List",
            path: "/parent/dashboard/my-fee",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Transport List",
        },
        {
            title: "Children Fees History",
            path: "/parent/dashboard/child-fee-history",
            icon: <i className="fas fa-plus"></i>,
            subtitle: "all record",
        },]
    },
    // {},
    {
        title: "Result",
        path: "parent/dashboard/Result",
        icon: <i className="fas fa-question-circle"></i>,

    },

    {
        title: "Academic Calender",
        path: "parent/dashboard/academic-calender"
    },
    {
        title: "Holiday List",
        path: "parent/dashboard/holiday",
        icon: <i className="fas fa-bell"></i>,

    },
    {
        title: "Notification ",
        path: "parent/dashboard/push-notification",
        icon: <i className="fas fa-chart-pie"></i>,

    },

    {
        title: "Parents ",
        path: "parent/dashboard/parents",
        icon: <i className="fas fa-comments"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: "Reset Password",
        path: "parent/dashboard/reset-password",
        icon: <i className="fas fa-bell"></i>,
    },
    {
        title: "Help",
        path: "parent/dashboard/help",
        icon: <i className="fas fa-credit-card"></i>,
    },
];

export const SuperAdminSidebarItems = [
    {
        title: "Dashboard",
        path: "superadmin/dashboard ",
        icon: <i className="fas fa-tachometer-alt"></i>, // Replace with your icon component
        subtitle: null,
        subpath: null,
    },
    {
        title: "Academic ",
        path: "superadmin/dashboard/academics/classCategory",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [{
            title: "Class Category",
            path: "/superadmin/dashboard/academics/classCategory",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Class Category",
        },
        {
            title: "Sections",
            path: "/superadmin/dashboard/academics/section",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Add New Section",
        },
        ]
    },
    {
        title: "Create School ",
        path: "superadmin/dashboard/create-school",
        icon: <i className="fas fa-credit-card"></i>,
    },


]

export const AdminSidebarItems = [
    {
        title: "Dashboard",
        className: "last-step",
        path: "admin/dashboard ",
        icon: <i className="fas fa-tachometer-alt"></i>,
        subtitle: null,
        subpath: null,
    },
    {
        title: "Class ",
        className: "academic",
        path: "admin/dashboard/academics/subject",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Subject",
                className: "subject",
                path: "/admin/dashboard/academics/subject",
                icon: <i className="fas fa-credit-card"></i>,
                subtitle: "Add New Subject",
            },
            {
                title: "Class Stream",
                className: "class-stream",
                path: "/admin/dashboard/academics/class-stream",
                icon: <i className="fas fa-credit-card"></i>,
                subtitle: "Add Class Stream",
            },
        ]
    },
    {
        title: "Students ",
        className: "student",
        path: "admin/dashboard/student/onboard",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [{
            title: "Onboard",
            path: "/admin/dashboard/student/onboard",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: " add new Onboard",
        },
        {
            title: "StudentList",
            path: "/admin/dashboard/student/studentlist",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Add New ",
        }
        ]
    },
    {
        title: "Parent ",
        className: "parent",
        path: "admin/dashboard/parent/onboard",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Onboard Parent",
                path: "/admin/dashboard/parent/onboard",
                icon: <i className="fas fa-credit-card"></i>,
            },
            {
                title: "onboarded Parent List",
                path: "/admin/dashboard/parent/onboarded-parentlist",
                icon: <i className="fas fa-credit-card"></i>,
                subtitle: "Add Class Stream",
            },
        ]
    },
    {
        title: "Assign Student to Parent",
        path: "admin/dashboard/assign-student-to-parent ",
        icon: <i className="fas fa-tachometer-alt"></i>, // Replace with your icon component
        subtitle: null,
        subpath: null,
    },
    {
        title: "Teachers ",
        className: "teacher",
        path: "admin/dashboard/teacher/onboard",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [{
            title: "Onboard Teacher",
            path: "/admin/dashboard/teacher/onboard",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: " add new Onboard",
        },
        {
            title: "Teacher List",
            path: "/admin/dashboard/teacher/onboardTeacherlist",
            icon: <i className="fas fa-credit-card"></i>,
            subtitle: "Add New ",
        }
        ]
    },
    {
        title: " Time Table",
        path: "admin/dashboard/timetable/create-new-time-table",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create New Time Table",
                path: "/admin/dashboard/timetable/create-new-time-table",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle: "Add New Subject",
            },
            // {
            //     title: "Get All Subject for All Class",
            //     path: "/admin/dashboard/timetable/get-all-subject-for-class",
            //     icon: <i className="fas fa-credit-card"></i>,
            // },
            {
                title: "Update Time Table For Particular Date",
                path: "/admin/dashboard/timetable/update-time-table-for-date",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle:""
            }
        ]
    },
    {
        title: " Exam Time Table",
        path: "admin/dashboard/exam-timetable/create-new-exam-time-table",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create New Exam Time Table",
                path: "/admin/dashboard/exam-timetable/create-new-exam-time-table",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle: "Add New Subject",
            },
            {
                title: "Exam Time Tables",
                path: "/admin/dashboard/exam-timetable-list",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle: "Add New Subject",
            },

        ]
    },
    {
        title: "Notice Board",
        path: "admin/dashboard/notice-board/publish-notice-board",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create New Notice",
                path: "/admin/dashboard/notice-board/publish-notice-board",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle: "Add New Subject",
            },
            {
                title: "All Notices",
                path: "/admin/dashboard/notice-board-list",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle: "Add New Subject",
            },

        ]
    },
    {
        title: "Subject",
        path: "admin/dashboard/subject/create-subject-chapter",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create Subject with chapter",
                path: "/admin/dashboard/subject/create-subject-chapter",
                icon: <i className="fas fa-credit-card"></i>,
            },
            {
                title: "Get All Subject with Chapter ",
                path: "/admin/dashboard/subject/all-subject-Chapter",
                icon: <i className="fas fa-credit-card"></i>,
            },

        ]
    }, {
        title: " Transport",
        path: "admin/dashboard/transport/create-new-route",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create new Route",
                path: "/admin/dashboard/transport/create-new-route",
                icon: <i className="fas fa-credit-card"></i>,
            },
            {
                title: "Add Student To Transport Route",
                path: "/admin/dashboard/transport/onboard-student-transport-route",
                icon: <i className="fas fa-credit-card"></i>,
            },
            {
                title: " All Student Transport Route List",
                path: "/admin/dashboard/transport/all-student-transport-route",
                icon: <i className="fas fa-credit-card"></i>
            }
        ]
    },
    {
        title: " Event",
        path: "admin/dashboard/event/create-new-event",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create new Event",
                path: "/admin/dashboard/event/create-new-event",
                icon: <i className="fas fa-credit-card"></i>,
            },

            {
                title: " All Events List",
                path: "/admin/dashboard/event/all-events-list",
                icon: <i className="fas fa-credit-card"></i>,
            }
        ]
    },
    {
        title: " Holidays",
        path: "admin/dashboard/holiday/create-new-holiday",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create new Holiday",
                path: "/admin/dashboard/holiday/create-new-holiday",
                icon: <i className="fas fa-credit-card"></i>,
            },

            {
                title: " All Holidays List",
                path: "/admin/dashboard/holiday/holiday-list",
                icon: <i className="fas fa-credit-card"></i>,
            }
        ]
    }, {
        title: "Student Fees",
        path: "admin/dashboard/student-Fee/notification",
        icon: <i className="fas fa-credit-card"></i>,
    },
    {
        title: "Class Fee",
        path: "admin/dashboard/class-fee",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "Create Class Fee",
                path: "/admin/dashboard/class-fee",
                icon: <i className="fas fa-credit-card"></i>,
            },
            {
                title: "Class Fee Status",
                path: "/admin/dashboard/class-fee-status",
                icon: <i className="fas fa-credit-card"></i>,
            },

        ]
    },
    {
        title: " Teacher Leave",
        path: "admin/dashboard/teacher-leave/teacher-leave-application",
        icon: <i className="fas fa-credit-card"></i>,
        Links: [
            {
                title: "All Leave Applications",
                path: "/admin/dashboard/teacher-leave/teacher-leave-application",
                icon: <i className="fas fa-credit-card"></i>,
                // subtitle: "Add New Subject",
            },


        ]
    },
    {
        title: "Help",
        path: "admin/dashboard/help",
        icon: <i className="fas fa-credit-card"></i>,
    },
 

];

