import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="font-poppins bg-gray-100 text-gray-800 min-h-screen p-8">
      <div className="max-w-6xl mx-auto bg-white rounded-2xl shadow-lg p-6 md:p-10">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-6">Privacy Policy</h1>
        <p className="text-sm text-gray-600 text-center mb-8">Effective Date: [Date]</p>
   

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">1. Information We Collect</h2>
            <p className="leading-7 mb-4">
              We may collect the following types of information:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <span className="font-semibold">Personal Information:</span> We do not collect personal information unless you provide it voluntarily. For example, if the app requires users to sign in or create an account, we may collect personal information such as name, email address, and grade level.
              </li>
              <li>
                <span className="font-semibold">Non-personal Information:</span> This includes information such as app usage, device type, IP address, and general usage patterns to improve our service. This information is collected through analytics services.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">2. How We Use Your Information</h2>
            <p className="leading-7">
              We use the information we collect to:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Provide and maintain the App’s functionality.</li>
              <li>Improve and personalize the App’s features.</li>
              <li>Communicate with users regarding updates or support.</li>
              <li>Analyze usage patterns to enhance the app’s performance.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">3. Data Sharing and Disclosure</h2>
            <p className="leading-7">
              We will not share or sell your personal data to third parties, except in the following circumstances:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <span className="font-semibold">Legal Requirements:</span> We may disclose information if required by law or in response to valid legal processes.
              </li>
              <li>
                <span className="font-semibold">Service Providers:</span> We may share your information with trusted third-party service providers who help us operate the App, such as cloud storage or analytics providers.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">4. Data Security</h2>
            <p className="leading-7">
              We take reasonable measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">5. Children’s Privacy</h2>
            <p className="leading-7">
              The App is not intended for use by children under the age of 13. We do not knowingly collect or solicit personal information from anyone under 13. If we learn that we have inadvertently collected such information, we will take steps to delete it.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">6. Changes to This Privacy Policy</h2>
            <p className="leading-7">
              We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest revision will be noted at the top. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">7. Contact Us</h2>
            <p className="leading-7">
              If you have any questions about this Privacy Policy or our data practices, please contact us at:
            </p>
            <address className="not-italic space-y-2">
              <p>Email: <a href="mailto:info@dtusker.com" className="text-blue-500">info@dtusker.com</a></p>
              <p>Phone: +971507461819</p>
              <p>Tusker LLC</p>
              <p>Shams Media City, Al-Messened, Al-Bataeh, Sharjah, UAE, PO Box 130130.</p>
            </address>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
