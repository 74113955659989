import React, { useEffect, useState } from 'react';
import Login from './Pages/LoginPage';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {
  TeacherDashboard, Dashboard, TimeTable, HolidayList
} from "./Component/Core/TeacherDashboard/index";
import { useDispatch } from 'react-redux';
import { setUser } from './Redux/slices/authSlice';
import {
  StudentDashboard, StudentDashboardPage, StudentDetailSubjects,
  StudentAllAssignment,
  StudentSubmitAssignment,
  StudentTimeTable,
  StudentNotice,
  StudentResultList,
  StudentExamResult,
  StudentParents,
  AttendancePage,
  AcademicCalender

} from './Component/Core/StudentDashboard';
import { ParentDashboardPage, ParentDashboard, TransPortVehicletList, VehicleRoute } from "./Component/Core/ParentDashboard"
import SuperAdminLoginForm from './Pages/SuperAdminPage/SuperAdminLogin';
import SuperAdminDashboard from './Pages/SuperAdminPage/SuperAdminDashboard';
import SuperAdminDashboardComp from './Component/Core/SuperAdminDashboard/SuperAdminDashboardComp';
import CreateSchool from './Component/Core/SuperAdminDashboard/CreateSchool';
import AdminLoginForm from './Pages/AdminPage/AdminLogin';
import AdminDashboard from './Pages/AdminPage/AdminDashboard';
import AdminDashboardComp from './Component/Core/AdminDashboard/AdminDashboardComp';
import ClassCategory from './Component/Core/AdminDashboard/ClassCategory';
import AdminCreateSection from './Component/Core/AdminDashboard/section';
import AdminCreateSubject from './Component/Core/AdminDashboard/CreateSubject';
import AdminClassStream from './Component/Core/AdminDashboard/ClassStream';
import GuradiansList from './Component/Core/AdminDashboard/GuradiansList';
import GuradiansStudentList from './Component/Core/AdminDashboard/GuradiansStudentList';
import OnboardedTeacherList from './Component/Core/AdminDashboard/OnboardedTeacherList';
import TeacherOnboard from './Component/Core/AdminDashboard/TeacherOnboard';
import OnboardStudent from './Component/Core/AdminDashboard/OnboardStudent';
import CreateTimeTable from './Component/Core/AdminDashboard/CreateTimeTable';
import CreateExamtimetable from './Component/Core/AdminDashboard/CreateExamtimetable';
import Noticeboard from './Component/Core/AdminDashboard/Noticeboard';
import StudentFee from './Component/Core/AdminDashboard/StudentFee';
import ParentOnboarded from './Component/Core/AdminDashboard/ParentOnboarded';
import CreateNewRoute from './Component/Core/AdminDashboard/CreateNewRoute';
import OnboardStudentToRoute from './Component/Core/AdminDashboard/OnboardStudentToRoute';
import TransportRouteList from './Component/Core/AdminDashboard/AllTranportRouteList';
import TeacherleaveList from './Component/Core/AdminDashboard/TeacherleaveList';
import AttendanceList from './Component/Core/TeacherDashboard/AttendanceList';
import StudentAttendanceDetail from './Component/Core/TeacherDashboard/StudentAttendanceDetail';
import LatestNotice from './Component/Core/TeacherDashboard/LatestNotice';
import ClassSubjectList from './Component/Core/TeacherDashboard/ClassSubjectList';
import SubjectOverViewDetails from './Component/Core/TeacherDashboard/SubjectOverView';
import StudyMaterialOverViewDetails from './Component/Core/TeacherDashboard/StudyMaterialOverview';
import ChapterMaterial from './Component/Core/TeacherDashboard/ChapterMaterial';
import CreateChapter from './Component/Core/TeacherDashboard/CreateChapter';
import MyLeave from './Component/Core/TeacherDashboard/MyLeave';
import SubmittedAssignment from './Component/Core/TeacherDashboard/SubmittedAssignment';
import DueAssignment from './Component/Core/TeacherDashboard/DueAssignment';
import CreateNewAssignment from './Component/Core/TeacherDashboard/CreateNewAssignment';
import UpdateAssignment from './Component/Core/TeacherDashboard/UpdateAssignment';
import StudentLeaveList from './Component/Core/TeacherDashboard/StudentLeaveList';
import TeacherLeaveList from './Component/Core/TeacherDashboard/MyLeaveApplications';
import ResultStudentList from './Component/Core/TeacherDashboard/ResultStudentList';
import OnboardedParentList from './Component/Core/AdminDashboard/OnboardedParentList';
import UpcomingEventsList from './Component/Core/AdminDashboard/UpcomingEventsList';
import CreateHoliday from './Component/Core/AdminDashboard/CreateHoliday';
import HolidaysList from './Component/Core/AdminDashboard/HolidayList';
import CreateEventForm from './Component/Core/AdminDashboard/CreateNewEventComponent';
import StudentAttendanceList from './Component/Core/StudentDashboard/StudentAttendanceList';
import StudentNoticeList from './Component/Core/StudentDashboard/NoticeList';
import MYStudentSubjectsList from './Component/Core/StudentDashboard/StudentSubjectList';
import StudyMaterial from './Component/Core/StudentDashboard/StudyMaterial';
import AllSubjects from './Component/Core/StudentDashboard/AllSubjects';
import MyReports from './Component/Core/StudentDashboard/MyReports';
import StudentLeave from './Component/Core/StudentDashboard/StudentLeave';
import StudentExamTimeTable from './Component/Core/StudentDashboard/StudentExamTimeTable';
import MyFees from './Component/Core/StudentDashboard/MyFees';
import StudentHolidayList from './Component/Core/StudentDashboard/StudentHolidayList';
import TimeTableList from './Component/Core/AdminDashboard/TimeTableList';
import ExamTimetableList from './Component/Core/AdminDashboard/ExamTimeTableList';
import SearchCreatedStudentResultList from './Component/Core/TeacherDashboard/SearchCreatedResult';
import Notification from './Component/Core/StudentDashboard/Notification';
import NotificationDetail from './Component/Core/StudentDashboard/NotificationDetail';
import AcademicHolidays from './Component/Core/StudentDashboard/AcademicHoliday';
import MyStudentAttendance from './Component/Core/ParentDashboard/MyStudentAttendance';
import ParentsProfile from './Component/Core/ParentDashboard/ParentsProfile';
import MyStudentAttendanceList from './Component/Core/ParentDashboard/MyStudentAttendanceList';
import ParentChildResult from './Component/Core/ParentDashboard/ParentChildResult';
import ParentExamResult from './Component/Core/ParentDashboard/ParentExamResult';
import ParentNotification from './Component/Core/ParentDashboard/ParentNotification';
import ParentNotificationDetails from './Component/Core/ParentDashboard/ParentNotificationDetails';
import ParentHolidayList from './Component/Core/ParentDashboard/ParentHoliday';
import ParentChildFeeList from './Component/Core/ParentDashboard/ParentChildFeeList';
import ParentChildFeeHistory from './Component/Core/ParentDashboard/ParentChildFeesHistory';
import ParentChildReport from './Component/Core/ParentDashboard/ParentChildReport';
import EventAndAnnouncement from './Component/Core/ParentDashboard/EventAndAnnouncement';
import ParentChildTimeTable from './Component/Core/ParentDashboard/ParentChildTimeTable';
import ParentChildLeave from './Component/Core/ParentDashboard/ParentLeave';
import OnboardSubjectChapter from './Component/Core/AdminDashboard/OnboardSubjectChapter';
import GetSubjectChapterList from './Component/Core/AdminDashboard/GetSubjectChapterList';
import AssignStudentParent from './Component/Core/AdminDashboard/AssignStudentParent';
import ClassFeeForm from './Component/Core/AdminDashboard/ClassFee';
import StudentFeeTable from './Component/Core/AdminDashboard/StudentFeeTable';
import ForgotPassword from './Component/Common/ForgotPassword';
import ResetPassword from './Component/Common/ResetPassword';
import SAdminForgotPassword from './Component/Core/AdminDashboard/SAdminforgotpassword';
import AdminVerifyForgotPasswordOtpComp from './Component/Core/AdminDashboard/AdminVerifyForgotPAsswordOtp';
import AdminNewPassword from './Component/Core/AdminDashboard/AdminNewPassword';
import AdminYoutube from './Component/Core/AdminDashboard/AdminYoutube';
import TeacherYoutube from './Component/Core/TeacherDashboard/TeacherToutube';
import NoticeBoardList from './Component/Core/AdminDashboard/NoticeboardList';
import Help from './Component/Common/Help';
import PrivacyPolicy from './Pages/PrivacyPolicy';

const getAuthStatus = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? { isAuthenticated: true, role: user?.role } : { isAuthenticated: false, role: null };
};

function PrivateRoute({ children, roleRequired }) {
  const auth = getAuthStatus();

  if (!auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (auth.role !== roleRequired) {
    switch (auth.role) {

      case 'super_admin':
        return <Navigate to="/superAdmin/dashboard" />
      case 'Teacher':
        return <Navigate to="/teacher/dashboard" />;
      case 'Student':
        return <Navigate to="/student/dashboard" />;
      case 'Parent':
        return <Navigate to="/parent/dashboard" />;
      default:
        return <Navigate to="/" />;
    }
  }

  return children;
}
function App() {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(getAuthStatus());
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      dispatch(setUser(user));
      setAuth({ isAuthenticated: true, role: user.role });
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route
          path="/"
          element={
            auth.isAuthenticated ? (
              auth.role === "Teacher" ? (
                <Navigate to="/teacher/dashboard" />
              ) : auth.role === "Student" ? (
                <Navigate to="/student/dashboard" />
              ) : auth.role === "Parent" ? (
                <Navigate to="/parent/dashboard" />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Login />
            )
          }
        />
        <Route path='/user/forgot-password' element={<ForgotPassword />}></Route>
        {/* <Route path='/user/reset-password' element={<ResetPassword />}></Route> */}
        <Route path='/superAdmin/login' element={<SuperAdminLoginForm />}></Route>
        <Route path="/superAdmin/dashboard" element={<PrivateRoute roleRequired="super_admin"><SuperAdminDashboard /></PrivateRoute>} >
          <Route index element={<SuperAdminDashboardComp />} />
          <Route path='academics/classCategory' element={<ClassCategory />} />
          <Route path='academics/section' element={< AdminCreateSection />} />
          <Route path='create-school' element={<CreateSchool />} />
        </Route>
        <Route path='/admin/login' element={<AdminLoginForm />}></Route>
        <Route path="/admin-reset-password" element={<SAdminForgotPassword />}></Route>
        <Route path="/admin-verify-forgot-password-otp" element={<AdminVerifyForgotPasswordOtpComp />}></Route>
        <Route path="/admin-new-password" element={<AdminNewPassword />}></Route>
        <Route path="/admin/dashboard" element={<AdminDashboard />}>
          <Route index element={<AdminDashboardComp />} />
          {/* <Route path='academics/classCategory' element={<ClassCategory />} /> */}
          {/* <Route path='academics/section' element={< AdminCreateSection />} /> */}
          <Route path='academics/subject' element={< AdminCreateSubject />} />
          <Route path='academics/class-stream' element={< AdminClassStream />} />
          <Route path='student/onboard' element={<OnboardStudent />} />
          <Route path='student/parent' element={<GuradiansList />} />
          <Route path="assign-student-to-parent" element={<AssignStudentParent />}></Route>
          <Route path='subject/create-subject-chapter' element={<OnboardSubjectChapter />} ></Route>
          <Route path='subject/all-subject-Chapter' element={<GetSubjectChapterList />} ></Route>
          <Route path='student/studentlist' element={<GuradiansStudentList />}></Route>
          <Route path="assign-student-to-parent" element={<AssignStudentParent />}></Route>
          <Route path='subject/create-subject-chapter' element={<OnboardSubjectChapter />} ></Route>
          <Route path='student/studentlist' element={<GuradiansStudentList />}></Route>
          <Route path='teacher/onboard' element={<TeacherOnboard />} />
          <Route path='teacher/onboardTeacherlist' element={<OnboardedTeacherList />} />
          <Route path='timetable/create-new-time-table' element={<CreateTimeTable />} />
          <Route path='timetable/update-time-table-for-date' element={<TimeTableList />} />
          <Route path='timetable/get-all-subject-for-class' element={<p>Not complete Api</p>} />
          <Route path='exam-timetable/create-new-exam-time-table' element={<CreateExamtimetable />} />
          <Route path='exam-timetable-list' element={<ExamTimetableList />} />
          <Route path='notice-board/publish-notice-board' element={<Noticeboard />} />
          <Route path='notice-board-list' element={<NoticeBoardList />} />
          <Route path='transport/create-new-route' element={<CreateNewRoute />}></Route>
          <Route path='transport/onboard-student-transport-route' element={<OnboardStudentToRoute />}></Route>
          <Route path='transport/all-student-transport-route' element={<TransportRouteList />}></Route>
          <Route path="student-Fee/notification" element={<StudentFee />} ></Route>
          <Route path="class-fee" element={<ClassFeeForm />} ></Route>
          <Route path="class-fee-status" element={<StudentFeeTable />} ></Route>
          <Route path="teacher-leave/teacher-leave-application" element={<TeacherleaveList />}></Route>
          <Route path='teacher-leave/teacher-review-leave-application' element={<p>Teacher review leave application</p>}></Route>
          <Route path='parent/onboard' element={<ParentOnboarded />}></Route>
          <Route path='parent/onboarded-parentlist' element={<OnboardedParentList />}></Route>
          <Route path='event/create-new-event' element={<CreateEventForm />}></Route>
          <Route path="event/all-events-list" element={<UpcomingEventsList />}></Route>
          <Route path='holiday/create-new-holiday' element={<CreateHoliday />}></Route>
          <Route path="holiday/holiday-list" element={<HolidaysList />}></Route>
          <Route path="help" element={<AdminYoutube />} />
        </Route>


        <Route path="/teacher/dashboard" element={<PrivateRoute roleRequired="Teacher"><TeacherDashboard /></PrivateRoute>} >
          <Route index element={<Dashboard />} />
          <Route path='timetable' element={<TimeTable />} />
          <Route path='reset-password' element={<ResetPassword />}></Route>
          <Route path='class/student-attendance/:studentId' element={<StudentAttendanceDetail />}></Route>
          <Route path='class/attendance-list' element={<AttendanceList />}></Route>
          <Route path='latest-notice' element={<LatestNotice />}> </Route>
          <Route path='class-subject-list' element={<ClassSubjectList />} />
          <Route path='subject-overview/:subjectId' element={<SubjectOverViewDetails />} />
          <Route path='study-material-overview/:subjectId' element={<StudyMaterialOverViewDetails />} />
          <Route path='holiday' element={<HolidayList />} />
          <Route path='material/Class-material' element={<ChapterMaterial />}></Route>
          <Route path='material/create-Chapter/:schoolId' element={<CreateChapter />}></Route>
          <Route path='result' element={<ResultStudentList />}></Route>
          <Route path='search/created-result' element={<SearchCreatedStudentResultList />}></Route>
          <Route path='myleave' element={<MyLeave />}></Route>
          <Route path='my-leave-applications' element={<TeacherLeaveList />}></Route>
          <Route path='student-leave-list' element={<StudentLeaveList />}></Route>
          <Route path='create/assignment' element={<CreateNewAssignment />}></Route>
          <Route path='edit-assignment/:assignmentId' element={<UpdateAssignment />}></Route>
          <Route path='get-due-assignment' element={<DueAssignment />}></Route>
          <Route path='submitted-assignment' element={<SubmittedAssignment />}></Route>
          <Route path='help' element={<TeacherYoutube />} />
        </Route>

        <Route path="/student/dashboard" element={<PrivateRoute roleRequired="Student"><StudentDashboardPage /></PrivateRoute>} >
          <Route index element={<StudentDashboard />} />
          <Route path='reset-password' element={<ResetPassword />}></Route>
          <Route path='attendance' element={<AttendancePage />} />
          <Route path='attendance-list' element={<StudentAttendanceList />}></Route>
          <Route path='timetable' element={<StudentTimeTable />} />
          <Route path="exam-timetable" element={<StudentExamTimeTable />}></Route>
          <Route path='notice-board' element={<StudentNoticeList />}></Route>
          <Route path="Subject-list" element={<AllSubjects />}></Route>
          <Route path='subject' element={<MYStudentSubjectsList />} />
          <Route path='detail/subject/:subjectId' element={<StudentDetailSubjects />} />
          <Route path='parents' element={<StudentParents />} />
          <Route path='detail/ExamResult/:examId' element={<StudentExamResult />} />
          <Route path='study-material/:subjectId/:chapterNumber' element={<StudyMaterial />}></Route>
          <Route path='Result' element={<StudentResultList />} />
          <Route path='my-reports' element={<MyReports />}></Route>
          <Route path='my-leave' element={<StudentLeave />}></Route>
          <Route path='assignment' element={<StudentAllAssignment />} />
          <Route path='submitted/Assignment' element={<StudentSubmitAssignment />} />
          <Route path='my-fee' element={<MyFees />}></Route>
          <Route path="holiday" element={<StudentHolidayList />}></Route>
          <Route path='announcment' element={<StudentNotice />} />
          <Route path='push-notification' element={<Notification />}></Route>
          <Route path="push-notification/notification-detail/:notificationId" element={<NotificationDetail />}></Route>
          <Route path='academic-calender' element={<AttendancePage />} />
          <Route path="academic-holiday" element={<AcademicHolidays />} />
          <Route path="academic-result" element={<StudentExamTimeTable />} />
          <Route path="help" element={<Help videoLink={"https://www.youtube.com/watch?v=4rtDaNz4Fv4"} />} />

        </Route>

        <Route path="/parent/dashboard" element={<PrivateRoute roleRequired="Parent"><ParentDashboardPage /></PrivateRoute>} >
          <Route index element={<ParentDashboard />} />
          <Route path='reset-password' element={<ResetPassword />}></Route>
          <Route path='attendance' element={<MyStudentAttendance />} />
          <Route path='student-attendance-list' element={<MyStudentAttendanceList />} />
          <Route path="holiday" element={<ParentHolidayList />}></Route>
          <Route path='my-fee' element={<ParentChildFeeList />}></Route>
          <Route path='child-fee-history' element={<ParentChildFeeHistory />}></Route>
          <Route path='my-child-reports' element={<ParentChildReport />}></Route>
          <Route path='transport' element={<TransPortVehicletList />} />
          <Route path='detail/transportRoute' element={<VehicleRoute />} />
          <Route path='timetable' element={<ParentChildTimeTable />} />
          <Route path='announcment' element={<EventAndAnnouncement />} />
          <Route path='academic-calender' element={<MyStudentAttendance />} />
          <Route path='my-child-leaveList' element={<ParentChildLeave />}></Route>
          <Route path='Result' element={<ParentChildResult />} />
          <Route path='detail/ExamResult/:resultId' element={<ParentExamResult />} />
          <Route path='parents' element={<ParentsProfile />} />
          <Route path='push-notification' element={<ParentNotification />}></Route>
          <Route path="push-notification/notification-detail/:notificationId" element={<ParentNotificationDetails />}></Route>
          <Route path="help" element={<Help videoLink="https://www.youtube.com/watch?v=7jkqDStnvBs&feature=youtu.be" />} />

        </Route>
      </Routes>
    </Router>
  );
}

export default App; 
